import postList from "../components/posts/posts.json";
import { compiler } from "markdown-to-jsx";

export async function getPosts() {
    const postsData = await Promise.all(
        postList.map(async (postName) => {
            const md = await import(`!!raw-loader!../components/posts/${postName}.md`).then(
                (module) => module.default
            );
            const splitMd = md.split("---");
            const metaString = splitMd[1];
            const contentString = splitMd[2];

            const metaLines = metaString.split(/\r?\n/).filter(Boolean);
            let data = {};

            for (let i = 0; i < metaLines.length; i++) {
                const line = metaLines[i];
                if (line.startsWith("tags:")) {
                    data.tags = [];
                    while (
                        metaLines[i + 1] &&
                        metaLines[i + 1].trim().startsWith("- ")
                    ) {
                        i += 1;
                        data.tags.push(metaLines[i].trim().slice(2));
                    }
                } else if (line.includes(":")) {
                    const splitLine = line.split(":");
                    data[splitLine[0].trim()] = splitLine.slice(1).join(":").trim();
                }
            }
            const content = compiler(contentString);
            return { id: postName, data, content };
        })
    );
    return postsData;
}

export async function getPost(postId) {
    const posts = await getPosts();
    return posts.find(post => post.id === postId);
}
