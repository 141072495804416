import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from '../components/layout/Navigation';
import HomePage from '../components/pages/HomePage';
import BlogPage from '../components/pages/BlogPage';
import PostPage from '../components/pages/PostPage';
import PublicMapPage from '../components/pages/PublicMapPage';
import Footer from '../components/layout/Footer';

const AppRouter = () => (
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/posts/:postId" element={<PostPage />} />
      <Route path="/public-map" element={<PublicMapPage />} />
    </Routes>
    <Footer />
  </Router>
);

export default AppRouter;
