import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPost } from "../../services/posts";
import '../../styles/component/pages/PostPage.css';

const PostPage = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        async function fetchPost() {
            const postData = await getPost(postId);
            setPost(postData);
        }
        fetchPost();
    }, [postId]);

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <div className="post-container">
            <h2>{post.data.title}</h2>
            <p className="date">{post.data.created}</p>
            <div className="tags">
                {post.data.tags.map((tag) => (
                    <span key={tag}>{tag}</span>
                ))}
            </div>
            <div>{post.content}</div>
        </div>
    );
};

export default PostPage;
