import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/global/App.css';

const HomePage = () => (
  <div className="homepage">
    <h1>Hello, I'm Eric</h1>
    <h2>Sr. Software Engineer with 20+ years of varied enterprise experience</h2>

    <div className="about-me">
      <p>
      Empowered by creativity, my two-decade journey through varied enterprise environments has seen me evolve from a user to a skilled Sr. Software Engineer. This creative drive has fueled my specialty in designing Azure Cloud Solutions, with a focus on Azure Blob Storage, Azure Functions, Azure SQL, Logic Apps, and more. As a Microsoft MVP, I thrive on constructing secure, efficient solutions, delivering engaging technical training, and contributing significantly to meaningful projects.
      </p>
      <img src="/me-ai.png" alt="Eric Thomas" className="profile-picture" /> 
    </div>
    
    <h2>Skills</h2>
    <div className="skills-grid">
      <div>Python</div>
      <div>SQL</div>
      <div>API's</div>
      <div>OpenAI Prompting</div>
      <div>MidJourney Prompting</div>
      <div>PowerFX</div>
      <div>Workflow Definition Language</div>
      <div>Bicep</div>
      <div>Markdown</div>
      <div>Azure Resource Provisioning / Management / Strategy</div>
      <div>Power Apps</div>
      <div>Power Automate</div>
    </div>
    
    <h2>Contact</h2>
    <p className="contact-me">
      Interested in discussing technology or think my skills could be useful for your organization? Feel free to get in touch:
    </p>
    <p>Email: (View page source or reverse the following): <span className="email-obfuscate">eric [at] ericthomas [dot] net</span></p>
    <p>Microsoft MVP Profile: <Link to="https://mvp.microsoft.com/en-us/PublicProfile/5003791?fullName=Eric%20Thomas">My MVP Profile</Link></p>
    <p>GitHub: <Link to="https://github.com/seadude">My GitHub Profile</Link></p>
    <p>LinkedIn: <Link to="https://www.linkedin.com/in/seadude/">My LinkedIn Profile</Link></p>
  </div>
);

export default HomePage;
