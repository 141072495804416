import React from 'react';
import AppRouter from '../router/AppRouter';
import '../styles/global/App.css';
import 'leaflet/dist/leaflet.css';


function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
