import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../styles/component/pages/PublicMap.css";
import L from "leaflet";
import Modal from "react-modal";
import { blueIcon } from "../utils"

function PublicMapPage() {
  const [pins, setPins] = useState([]);
  const [loading, setLoading] = useState(true);
  const mapRef = useRef();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchPinsAndPhotos = async () => {
      try {
        const pinsResponse = await fetch("/data-api/rest/Pin");
        const pinsData = await pinsResponse.json();

        if (Array.isArray(pinsData.value)) {
          const photosResponse = await fetch("/data-api/rest/Photo");
          const photosData = await photosResponse.json();

          if (Array.isArray(photosData.value)) {
            const pinsWithPhotos = pinsData.value.map((pin) => {
              const matchingPhotos = photosData.value.filter(
                (photo) => photo.pinGuid === pin.pinGuid
              );
              return {
                ...pin,
                photos: matchingPhotos,
              };
            });

            setPins(pinsWithPhotos);
          } else {
            console.error("Error fetching photos: Invalid data format");
          }
        } else {
          console.error("Error fetching pins: Invalid data format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPinsAndPhotos();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-message">Loading...</div>
      </div>
    );
  }

  const latLngs = pins.map((pin) => [pin.pinLat, pin.pinLng]);
  const bounds = L.latLngBounds(latLngs);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <MapContainer
      center={bounds.getCenter()}
      bounds={bounds}
      className="map-container"
      whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {pins.map((pin) => (
        <Marker key={pin.pinId} position={[pin.pinLat, pin.pinLng]} icon={blueIcon}>
          <Popup>
            <div className="popup-content">
              <b>{pin.pinName}</b> <br />
              {pin.pinNotes}
              <div className="photos-container">
                {pin.photos &&
                  pin.photos.map((photo) => (
                    <img
                      key={photo.photoId}
                      src={photo.photoBlobUrl}
                      alt=""
                      className="photo"
                      onClick={() => handlePhotoClick(photo)}
                    />
                  ))}
              </div>
            </div>
          </Popup>
        </Marker>
      ))}
      <Modal
        isOpen={modalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Pin Photo Modal"
        className="modal"
      >
        {selectedPhoto && (
          <img
            src={selectedPhoto.photoBlobUrl}
            alt="Selected Pin Photo"
            className="modal-photo"
          />
        )}
        <button className="modal-close" onClick={handleModalClose}>
          Close
        </button>
      </Modal>
    </MapContainer>
  );
}

export default PublicMapPage;
