import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav>
      <Link to="/">
        <img src="/logo.png" alt="ericthomas.net logo" className="logo" onClick={() => setIsOpen(false)} />
      </Link>
      <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
        &#9776;
      </button>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={() => setIsOpen(false)}>Home</Link></li>
        <li><Link to="/blog" onClick={() => setIsOpen(false)}>Blog</Link></li>
        <li><Link to="/public-map" onClick={() => setIsOpen(false)}>Map</Link></li>
      </ul>
    </nav>
  );
};

export default Navigation;
